.Page{
    display: flex;
}

.Content{
    margin-left: 280px;
    width: 100vw;
    background-color: #F4F5F7;
    height: 100vh;
    overflow-y: scroll;
}

.DashboardViews{
    padding: 32px;
    display: flex;
}

.FilterColumn{
    width: 320px;
}

.FilterButton{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    font-weight: 700;
}

Text {
    font-family: 'Inter';
}

.ResetButton{
    background-color: #FFFFFF;
    font-family: 'Inter';
    color: gray;
    border-color: #FFFFFF;
    box-shadow: none;
    font-weight: 700;
    margin-left: 20px;
}

.ResetButton:hover{
    background-color: #FFFFFF;
}

.OpenOrderBtn{
    background-color: #FFFFFF;
    color: #794396;
    border-color: #794396;
    width: 25px;
    height: 25px;
    padding: 0px;
    margin-top: 15px;
}

.OpenOrderBtn:hover{
    background-color: #794396;
    color: #FFFFFF;
}

.TableField{
    font-size: smaller;
}

.DownloadCSVButton {
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 20px;
    font-weight: 700;
    width: 120px;
    float: right;
    margin-right: 120px;
}

.DownloadCSVContainer{
    justify-content: end;
}

#input {
    background-color: #794396 !important;
}

.OpenStickerButton{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 20px;
    font-weight: 700;
    width: 120px;
    float: right;
    margin-right: 20px;
}

.DateSwitch {
    margin-right: 20px;
    margin-top: 25px;
}