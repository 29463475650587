.AddOrderBtn{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    font-weight: 700;
    margin-right: 20px;
}

.RemoveSelectedOrderBtn{
    background-color: #FFFFFF;
    color: #794396;
    border-color: #794396;
    width: 30px;
    height: 25px;
    padding: 0px;
    margin-top: 10px;
    margin-right: 160px;
}

.RemoveSelectedOrderBtn:hover{
    background-color: #794396;
    color: #FFFFFF;
}